@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.filtersDropDown {
  background-color: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(50px);
  position: absolute !important;
  top: calc(100% + 21px) !important;
  bottom: 0px !important;
  left: -25px !important;
  z-index: 99;
  width: calc(100% + 50px);
}

.filtersDropDownInner {
  background-color: $paper !important;
  box-shadow: 0px 8px 8px -4px rgba($primaryDark, 0.03),
    0px 20px 24px -4px rgba($primaryDark, 0.08);
    
    .filtersDropDownInnerContainer {
      max-height: calc(100vh - 245px); 
      overflow-y: auto;
    }
}

@media (max-width: 1200px) {
  .filtersDropDown {
    top: calc(100% + 10px) !important;
    left: -20px !important;
    z-index: 99;
    width: calc(100% + 40px);
  }
}
