@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.commonButton {
  padding: 4px 8px;
  border-radius: 15px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 5px;
  margin-right: 5px;
  text-transform: capitalize;
}

.planning,
.draft,
.open,
.booked,
.closed,
.completed,
.canceled {
  @extend .commonButton;
}

.planning,
.draft {
  color: $grey800;
  background-color: $grey150;
}

.open {
  color: $secondary500;
  background-color: $secondaryLight;
}

.completed, .booked {
  color: $success300;
  background-color: $successLight;
}

.closed {
  background-color: $error50;
  color: $error700;
}
