@import "variables.scss";
.logListItem {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  &.isFromRight {
    justify-content: flex-end;
  }
  &.isEmpty {
    margin-bottom: 0;
  }
}
