@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.reachedLimitPopupWrapper {
  text-align: center;

  .limitWarnIcon {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  .headerPopup {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: $primary900;
  }

  .descriptionPopup {
    color: $grey800;
    max-width: 400px;
  }
}
