@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.chatTabWrapper {
  height: 100%;
  background-color: $secondary100;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &.withClosedHeader {
    height: calc(100% - 80px);
  }

}

.inquiryMessagesList {
  display: flex;
  flex-direction: column;
  padding: 20px 40px 40px;
  list-style: none;
  margin: 0;
  background-color: $secondary100;
}

@media (max-width: $breakpointMD) {
  .inquiryMessagesList {
    padding: 20px;
  }
}
