@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.paymentSummaryContainer {
  padding: 16px;
  background-color: $dark300;
  border-radius: 2px;
  margin: 20px;

  &.isOverdue {
    background: rgb(69, 80, 100);
    background: linear-gradient(
      86deg,
      rgba(69, 80, 100, 1) 0%,
      rgba(87, 71, 89, 1) 95%
    );
  }

  &.canceled {
    background: $grey100;

    .paymentSummaryProgress span,
    .paymentSummaryText {
      color: $darkTextTitle;
    }
  }
}

.paymentSummary {
  display: flex;
  justify-content: space-between;
}

.paymentSummaryTitle {
  color: $darkTextTitle;
  font-size: 12px;
}

.paymentSummarySubTitle {
  color: $darkTextTitle;
  font-size: 12px;
  margin-bottom: 3px;
}

.paymentSummaryText {
  color: $paper;
  font-size: 16px;

  &.zeroPayment {
    color: $darkTextTitle;
  }
}

.paymentSummaryProgressContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paymentSummaryProgress {
  color: $darkTextTitle;
  font-size: 20px;

  span {
    color: $paper;
    font-size: 40px;
    font-weight: 700;
  }
}

.payInvoiceButton {
  padding: 0 8px;
  border-radius: 100px;
  background-color: $secondaryLight;
  color: $secondaryMain;
  font-size: 12px;

  &.overdue {
    color: $errorMain;
    background-color: $error50;
  }
}

.paymentSummaryDivider {
  margin: 10px 0;
  background: $paper;
  opacity: 0.16;
}

.nextPaymentInfo {
  border-radius: 2px;
  background-color: $grey200;
  padding: 16px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  h6 {
    font-weight: 400;
  }
}

@media only screen and (min-width: $breakpointMD) and (max-width: 1200px) {
  .paymentSummaryText {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: $breakpointLG) {
  .paymentSummaryText {
    font-size: 14px;
  }
}

@media only screen and (min-width: $breakpointMD) and (max-width: $breakpointLG) {
  .paymentSummaryContainer {
    padding: 10px;
    margin: 10px;
  }

  .paymentSummaryProgress {
    span {
      font-size: 32px;
    }
  }

  .nextPaymentInfo {
    padding: 10px;
    margin: 10px;
  }
}

@media (max-width: $breakpointMD) {
  .paymentSummaryContainer {
    margin: 16px;
  }

  .paymentSummaryProgress {
    font-size: 16px;
    span {
      font-size: 28px;
    }
  }

  .paymentSummaryText {
    font-size: 14px;
  }

  .nextPaymentInfo {
    padding: 16px;
    margin: 16px;
  }
}
