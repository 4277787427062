@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.dataItemWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 12px;
  border-bottom: 1px solid $grey150;
  padding-bottom: 12px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .dataItemTitle {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: $grey800;
  }

  .dataItemContent {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .marked {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      font-weight: bold;
    }

    .dateFlexibilityElementWrapper {
      display: flex;
      width: 45px;
      justify-content: space-between;
      align-items: center;
    }

    .withRightOffset {
      padding-right: 80px;
    }
  }
}
