@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.inquiriesContainer {
  padding: 16px 0;
  position: relative;
  height: calc(100vh - 127px);

  .inquiriesListTitle {
    font-size: 16px;
    padding: 15px 20px 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: $darkPaper;
    margin-bottom: 10px;

    .inquiriesListAmount {
      color: $grey400;
    }
  }

  .filters {
    padding: 20px 25px;
    border-bottom: 1px solid $grey300;
    box-shadow: 0px 1px 0px 0px rgba($primary900, 0.25);
    position: relative;

    .filtersWrapper {
      position: relative;
      display: flex;
      align-items: center;

      .appliedFiltersAmount {
        position: absolute;
        right: -10px;
        top: -15px;
        z-index: 999;
        font-size: 14px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $secondaryLight;
        color: $secondaryMain;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .buttonCollapse {
    position: fixed;
    opacity: 1;
    z-index: 1;
    top: 100px;
    left: 19.5%;
    padding: 0;
    min-width: 30px;
    height: 30px;
  }
}

.filterItem {
  padding-bottom: 20px;
  border-bottom: 1px solid $grey300;
  margin: 0 10px;
  padding: 20px 0;
  display: block;

  &:nth-of-type(3) {
    border-width: 0px;
  }

  &:hover {
    background: $paper !important;
  }

  .filterTitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    color: $grey500;
    margin-bottom: 10px;
  }
}

@media (max-width: 1200px) {
  .inquiriesContainer {
    padding: 10px 0;

    .inquiriesListTitle {
      font-size: 14px;
      padding: 15px 15px 20px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    .filters {
      padding: 15px 20px 10px;

      .filtersWrapper {
        .appliedFiltersAmount {
          font-size: 12px;
          width: 18px;
          height: 18px;
          top: -12px;
          right: -3px;
        }
      }
    }

    .inquiryItem {
      padding: 15px 10px;

      .commonText {
        font-size: 10px;
        line-height: 14px;
      }

      .buttonsList {
        max-width: 60%;
      }
    }
  }
}

@media (max-width: $breakpointMD) {
  .inquiriesContainer {
    padding: 10px 20px;
    height: unset;
    
    .filters {
      padding: 15px 20px 0;
      .filtersWrapper {
        .appliedFiltersAmount {
          right: 12px;
          top: -13px;
        }
      }
    }
  }
}
