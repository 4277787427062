@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.noCreatedIquiriesWrapper {
  position: relative;
  min-height: 80vh;
}

.noInquiryDialog {
  padding: 20px;
  display: flex;
  justify-content: center;
  text-align: center;

  .iconWrapper {
    padding: 20px 0 10px;
    display: flex;
    justify-content: center;
  }

  .noInquiryTitle {
    margin-bottom: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 0 35px;
    color: $primaryDark;
  }

  .noInquiryText {
    font-size: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $grey800;
    margin-bottom: 20px;
  }

  .noInquiryDialogButton {
    color: $paper;
    background-color: $secondaryMain;
    margin: 0 20px 15px;
  }
}

@media (max-width: $breakpointMD) {
  .commonWrapperStyles {
    height: 80vh;
  }
  .noInquiriesWrapper {
    padding: 80px 20px 0;
  }

  .noInquiryDialog {
    .iconWrapper {
      padding: 10px 0;
    }
  }
}
