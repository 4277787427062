@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.contentWrapper {
  .rateTitle {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: $primary900;
    margin-bottom: 12px;
  }

  .ratingList {
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .ratingListItem {
      list-style: none;
      padding-left: 0;
      padding: 10px 10px 5px;
      cursor: pointer;

      text-align: center;

      &.selected {
        background-color: $secondary150;
      }
    }
  }

  .textBox {
    border-width: 1px;
    border-style:  solid;
    border-color:  $grey400;
    background-color: $grey150;
    min-height: 100px;
    padding: 14px 8px;
    margin-top: 12px;
    width: 100%;
    resize: none;

    &.error {
      border-color: $error700;
    }
  }

  .errorMessage {
    color: $error700;
  }

  .sendButton {
    width: 100%;
  }
}
