@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

/**
 * Listing Grid
 */

.listingHeader {
  border-bottom: 1px solid var(--grey-300);
  position: relative;
  overflow: visible;
  height: 80px;
}

.listingHeaderBgImageContainer {
  position: absolute;
  top: -80px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 160px;
  z-index: -1;
  overflow: hidden;
}

.listingHeaderBgImage {
  width: 100%;
  height: 160px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.listingHeaderBgImageOverlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.26);
}

.listingHeaderInner {
  display: flex;
  justify-content: space-between;
  padding: 20px 18px 12px 12px;
}

/**
 * Listing Filters / Tags
 */

.listingFilters {
  width: var(--listingFilterWidth) !important;
  border-right: 1px solid var(--grey-200);
  max-height: 2300px;
  overflow-y: scroll;
}

.quickFiltersContainer {
  display: block;
  width: calc(60% - 16px);
}

.listingFilters::-webkit-scrollbar {
  display: none;
}

.headerButtonsContainer {
  display: flex;
  gap: 32px;
}

.listFiltersTitle {
  display: none;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  gap: 4px;
  padding: 12px 12px 12px;
  border-bottom: 1px solid var(--grey-300);
}

/**
 * Listing Grid
 */

.listingContainer {
  --listingFilterWidth: 230px;
  padding: 0;
  display: flex;
  min-height: inherit;
  width: 100%;

  & > div {
    width: 100%;
    height: 100%;
  }
}

.listingContainer:global(.map) {
  overflow-y: scroll;

  & .listingGrid {
    height: 100%;
    padding: 0px;
    max-width: 60%;
  }

  height: calc(100vh - 160px);
}

.noResultsWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;

  @media (min-width: $breakpointMD) {
    min-height: calc(100vh - 200px);
  }

  :global(.venue) {
    :global(.popular) {
      width: 100%;
      max-width: calc(100% - 2px);
    }
  }
}

.listingGrid {
  width: 100%;
  margin-bottom: 40px;
}

.listingGrid:global(.map) {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 60%;
  margin-bottom: 0px !important;
  height: fit-content;
}

.listingPagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.listingPagination:global(.map) {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 99;
  background: var(--common-white);
}

/**
 * Tags for filters / search
 */
.listingGridTagsRow {
  display: flex;
  justify-content: flex-start;
  padding: 16px 16px 0px 17px;
  gap: 12px;
}

.listingGridTagsRow:global(.map) {
  overflow-x: scroll;
  margin-bottom: 8px;
}

.listingGridTagsRow:global(.map)::-webkit-scrollbar {
  display: none;
}

.listingGridTagActual {
  text-transform: capitalize;
}

.listingMap {
  display: none;
}

.listingMap:global(.map) {
  display: block;
  width: 100%;
  max-width: 40%;
  min-width: 40%;
}

/**
 * Pagination
 */

.listingPaginationOption {
  text-decoration: none !important;
}

@media (max-width: $breakpointXL) {
  .listingHeaderInner {
    padding: 20px 16px;
  }

  .listingGrid:global(.map) {
    max-height: inherit;
    padding: 0 0 32px;
    width: 60%;
    height: fit-content;
  }

  .listingMap:global(.map) {
    display: block;
    width: 40%;
    max-width: 40%;
  }

  .listingCount {
    color: $dark300;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .listingFilters {
    max-height: 3100px;
  }
}

@media (max-width: $breakpointLG) {
  .listingFilters {
    max-height: 4100px;
  }
}

@media (max-width: $breakpointMD) {
  .listingHeaderInner {
    max-width: 100vw;
    overflow: scroll;
  }

  .listingHeaderInner::-webkit-scrollbar {
    display: none;
  }

  .headerButtonsContainer {
    width: 100%;
    justify-content: space-between;
  }

  .listingFilters,
  .quickFiltersContainer {
    display: none;
  }

  .listingMap:global(.map) {
    width: 100%;
    max-width: 100%;
  }

  .listingGrid,
  .listingGrid:global(.map) {
    width: 100%;
  }

  .listingGridTagsRow {
    flex-wrap: wrap;
  }
}
