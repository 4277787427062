@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.progressBar {
  position: relative;
  height: 4px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  background-color: $paper;
  margin: 8px 0;

  &.notCompleted {
    background-color: $grey800;
  }

  .progressSection {
    background-color: $paper;
    height: 100%;
    flex: 1;
  }

  .completed {
    background-color: $secondary400;
  }

  .next {
    display: block;
    position: relative;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      $secondary400,
      $secondary400 60%
    );
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        -45deg,
        $paper 25%,
        transparent 25%,
        transparent 50%,
        $paper 50%,
        $paper 75%,
        transparent 75%,
        transparent
      );
      z-index: 1;
      background-size: 5px 5px;
      overflow: hidden;
    }
  }

  .roundedOnLeft {
    border-radius: 24px 0 0 24px;
  }

  .roundedOnRight {
    border-radius: 0 24px 24px 0;
  }
}
