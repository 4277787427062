@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.filterItem {
  padding-bottom: 20px;
  border-bottom: 1px solid $grey300;
  margin: 0 10px;
  padding: 20px 0;
  display: block;

  &:nth-of-type(3) {
    border-width: 0px;
  }

  &:hover {
    background: $paper !important;
  }

  .filterTitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    color: $grey400;
    margin-bottom: 10px;
  }
}

.clearFiltersSection {
  padding: 10px;
  display: flex;

  .transition {
    transition: all 0.3s ease-in-out;
  }

  .clearButton {
    @extend .transition;

    padding: 14px 10px;
    margin-right: 10px;
    background-color: $grey200;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: $grey600;

    &:hover {
      background-color: $grey400;
    }
  }
  .applyButton {
    @extend .transition;

    padding: 14px 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: $paper;
    background-color: $secondaryMain;
    flex-grow: 2;

    &:hover {
      background-color: $secondaryDark;
    }
  }
}

@media (max-width: 1200px) {
  .inquiriesContainer {
    padding: 10px 0;

    .inquiriesListTitle {
      font-size: 14px;
      padding: 15px 15px 20px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    .filters {
      padding: 0 8px 8px 20px;

      .filtersWrapper {
        .appliedFiltersAmount {
          font-size: 12px;
        }
      }
    }

    .inquiryItem {
      padding: 15px 10px;

      .commonText {
        font-size: 10px;
        line-height: 14px;
      }

      .buttonsList {
        max-width: 60%;
      }
    }
  }
}

@media (max-width: $breakpointMD) {
  .filterItem {
    padding: 20px 0;

    &:nth-of-type(3) {
      border-width: 1px;
    }
  }

  .clearFiltersSection {
    flex-direction: column-reverse;

    .applyButton {
      margin-bottom: 10px;
    }

    .clearButton {
      margin-right: 0;
    }
  }
}
