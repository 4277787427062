@import "variables.scss";
.toastContainer {
  display: flex;
  align-items: center;
}

.toastMessage {
  color: var(--grey-700);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 0;
  margin-right: 15px;
}

.toastButton {
  padding: 8px 10px;
  border-color: var(--grey-700);
  font-size: 12px;
  color: var(--grey-700);
  font-style: normal;
  font-weight: 500;
}

.toastButton:hover {
  border-color: var(--grey-900);
}

@media (max-width: $breakpointXS) {
  .toastMessageSuccess {
    margin-bottom: 5px;
    width: calc(100% - 112px);
  }
}
