@import "variables.scss";
.venueQuickFacts {
  display: flex;
  margin-top: -4px;
  margin-bottom: 4px;
  gap: 24px;
}

.venueQuickFactsItem {
  display: flex;
  gap: 6px;
}

@media (max-width: $breakpointMD) {
  .venueQuickFacts {
    margin-top: 12px;
    margin-bottom: 12px;
    justify-content: space-between;
  }
}

@media (max-width: $breakpointSM) {
  .venueQuickFacts {
    flex-wrap: wrap;
  }

  .venueQuickFactsItem {
    width: calc(50% - 13px);
  }
}
