@import "variables.scss";
.vendorTypeDescription {
  margin-top: -8px;
  margin-bottom: 40px;
}

.venueVendorList {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.venueVendorContainer {
  display: flex;
  gap: 16px;
}

.venueVendorImages {
  width: 400px;
  max-width: calc(100vw - 12px);
  height: 200px;
}

.venueVendorImages > div {
  height: inherit !important;
}

.venueVendorImages > div > div:first-child {
  height: inherit !important;
}

.venueVendorImageInnerImg {
  width: 100%;
  height: 200px;
}

.venueVendorDetail {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 16px;
}

.venueVendorDetailHeader {
  display: flex;
  justify-content: space-between;
}

.hideLinkStyles {
  text-decoration: none;
}

@media (max-width: $breakpointMD) {
  .venueVendorContainer {
    flex-direction: column;
  }

  .venueVendorDetailHeader {
    flex-direction: column;
    justify-content: flex-start;
  }

  .venueVendorImages {
    width: 100%;
  }
}
