@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.middlePanelTabs :global .MuiTabs-flexContainer {
  gap: 16px;
  padding: 0 40px;
}

.middlePanelTabs {
  min-height: 20px;
}

.middlePanelTabs :global .MuiTabs-indicator {
  background-color: $dark300;
  bottom: 0;
}

.middlePanelTab {
  padding: 8px 0 12px;
  min-width: fit-content;
  font-size: 12px;
  font-weight: 600;
  min-height: 30px;
  color: $grey800;

  &.selected,
  &:hover {
    color: $dark300;
  }

  &.withNotifications {
    padding-right: 10px;

    &::after {
      content: "";
      background-color: $errorMain;
      width: 5px;
      height: 5px;
      position: absolute;
      top: 8px;
      right: 1px;
      border-radius: 100%;
    }
  }
}

@media (max-width: $breakpointMD) {
  .middlePanelTabs :global .MuiTabs-flexContainer {
    gap: 16px;
    padding: 0 20px;
  }
}
