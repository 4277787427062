@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 213px;
  overflow-y: scroll;

  .contentTitle {
    color: $grey800;
    font-size: 12px;
    line-height: 16px;
  }

  .contentText {
    font-size: 12px;
    line-height: 16px;
  }
}
