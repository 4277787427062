@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.panelText {
  font-size: 12px;
  color: $grey800;

  &.withoutIcon {
    margin-left: 20px;
  }
}

.panelDetailsWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;

  .icon {
    color: $grey800;
  }
}

.emptyBox {
  width: 16px;
  height: 16px;
}

.businessImage {
  object-fit: cover;
  border-radius: 50%;
}
