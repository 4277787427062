@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.loglist {
  margin-bottom: 20px;
  list-style: none;
  padding-left: 0;

  .listItem {
    display: flex;
  }

  .logListDateWrapper {
    display: flex;
    justify-content: center;
    margin: 8px 0;
  }

  .logListDate {
    color: $grey750;
    background: $grey150;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 100px;
  }
}
