@import "variables.scss";
.headerBgImageWrapper {
  width: 100%;
  height: 100px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
}

.similarWrapper {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 20px;

  .similarHeading {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--common-white);
    padding-right: 40px;
    position: relative;

    @media (max-width: $breakpointSM) {
      font-size: 16px;
    }

    &::after {
      content: "";
      width: 1px;
      height: 100%;
      background-color: var(--common-white);
      position: absolute;
      right: 20px;
      top: 0;
    }

    .similarVendorIcon {
      margin-right: 10px;
    }
    .similarVenueIcon {
      margin-right: 3px;
    }
  }

  .textRecommend {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: var(--common-white);
    max-width: 442px;

    @media (max-width: $breakpointSM) {
      font-size: 12px;
    }
  }
}
