@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.inputWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 -5px;
}

.filtersInput {
  width: 100%;
  border-width: 0;
  margin: 0 8px;
  font-size: 14px;
  flex-grow: 2;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $primary900;
  margin-top: -2px;
  border-bottom: 1px solid $grey750;
  padding-bottom: 4px;

  &::placeholder {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: $grey750;
  }
}

.filterButton {
  min-width: 16px;
  width: 16px;
  padding: 0;
  height: 16px;
}

@media (max-width: 1200px) {
  .inputWrapper {
    position: relative;
  } 
}

@media (max-width: $breakpointMD) {
  .inputWrapper {
    margin-bottom: 10px;
    padding-left: 10px;
    margin-right: 20px;
  }
}
