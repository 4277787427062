@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.imageWrapper {
  object-fit: cover;
  border-radius: 50%;
}

.userInitial {
  @extend .imageWrapper;

  width: 40px;
  min-width: 40px;
  height: 40px;
  background-color: $secondaryLight;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userInitialText {
  text-transform: uppercase;
  color: $grey800;
  font-weight: 400;
}
