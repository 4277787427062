@import "variables.scss";
.dragDialog {
  & :global .MuiDialog-container {
    align-items: flex-end;
  }
  & :global .MuiDialog-paper {
    max-width: 100%;
    width: 100%;
    height: 90vh;
    margin: 0;
  }
}
