@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.messageWrapper {
  position: relative;
  margin: 16px 0;
  max-width: 75%;
  width: fit-content;

  &.fullEmail {
    width: inherit;
  }

  &.fromRight {
    align-self: end;

    .messageTime {
      text-align: right;
    }
  }

  .inner {
    display: flex;
    gap: 12px;

    &.fromRight {
      flex-direction: row-reverse;
    }
  }

  .messageBox {
    width: 100%;
  }

  .message {
    display: flex;
    padding: 8px 12px 12px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    min-width: 280px;
    width: inherit;
    background-color: $secondary150;
    border-radius: 0 12px 12px 12px;
    position: relative;

    .confettiWrapper {
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
    }

    &.fromRight {
      border-radius: 12px 0px 12px 12px;
      background-color: $paper;
    }
  }

  .titleIcon {
    display: flex;
    padding: 4px;
    align-items: flex-start;
    border-radius: 12px;

    &.submitIconWrapper {
      background-color: $success50;
    }
  }

  .commonTextStyles {
    font-size: 12px;
    line-height: 16px;
  }

  .messageTitle {
    @extend .commonTextStyles;

    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .email {
    @extend .commonTextStyles;

    color: $grey400;
    letter-spacing: 0em;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .subject {
    @extend .commonTextStyles;

    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: $grey800;
    padding-right: 30px;
  }

  .showButton {
    @extend .commonTextStyles;

    font-weight: 500;
    letter-spacing: 0em;
    color: $secondary500;
    margin: 0 auto;
  }

  .messageContent {
    padding: 12px;
    width: 100%;
    background-color: $paper;

    &.withBorder {
      border-radius: 8px;
      border: 1px solid $grey150;
    }
  }

  .messageTime {
    color: $grey750;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    cursor: pointer;

    .fullUpdatedDate {
      display: none;
    }

    &:hover {
      .fullUpdatedDate {
        display: block;
      }
      .shortUpdatedDate {
        display: none;
      }
    }
  }
}

@media (max-width: $breakpointXS) {
  .messageWrapper {
    max-width: unset;
    width: unset;

    .message {
      max-width: 360px;
      width: fit-content;
    }
  }
}
