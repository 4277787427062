@import "variables.scss";
.mainWrapper {
  position: relative;
  height: 100%;
  padding-bottom: 40px;

  .inquiryTitle {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    margin: 0 0 16px;
    padding: 0 40px;
  }

  .buttonCollapse {
    transition: all ease-in-out 0.3s;
    position: absolute;
    opacity: 1;
    z-index: 100;
    top: -5px;
    padding: 0;
    min-width: 30px;
    height: 30px;
    visibility: visible;

    &.buttonCollapseFaqOpen {
      opacity: 0;
      visibility: hidden;
    }
  }

  .buttonCollapseLeft {
    @extend .buttonCollapse;

    left: -20px;
  }

  .buttonCollapseRight {
    @extend .buttonCollapse;

    display: none;
  }

  .mainContainerHeader {
    display: flex;
    flex-direction: row;
  }

  .titleLine {
    width: 100%;
  }

  .backButton {
    align-items: flex-start !important;
    margin: 4px 0px 0px 8px !important;
    padding: 0px !important;
    min-width: 40px;
  }
}

@media (max-width: 1200px) {
  .mainWrapper {
    .inquiryTitle {
      font-size: 32px;
      line-height: 35px;
    }

    .buttonCollapseRight {
      display: block;
      right: -20px;
      top: -5px;
    }
  }
}

@media (max-width: $breakpointMD) {
  .mainWrapper {
    .buttonCollapseLeft,
    .buttonCollapseRight {
      display: none;
    }

    .inquiryTitle {
      font-size: 26px;
      padding-right: 120px;
      padding-left: 0px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 20px;
      margin-top: 0px;
    }

    .titleLine {
      padding-right: 16px;
    }
  }
}
