@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.rightSidebarWrapper {
  font-size: 16px;
  position: relative;

  .buttonRightSidebarCollapse {
    transform: rotate(360deg);
    position: fixed;
    opacity: 1;
    z-index: 1;
    top: 100px;
    right: 19.5%;
    padding: 0;
    min-width: 30px;
    height: 30px;
  }

  .accordionDetails {
    padding: 0 20px 20px;
  }

  .panelDetailsWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;

    &.title {
      gap: 8px;
    }

    .link {
      font-size: 12px;
      color: $secondaryMain;
    }
  }

  .panelDetailsTitle {
    font-size: 12px;
    color: $primaryDark;
  }

  .linkTo {
    text-decoration: none;
    color: $secondaryMain;
  }

  .businessImage {
    object-fit: cover;
    border-radius: 50%;
  }

  .managerEmail {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .marginBottomSm {
    margin-bottom: 8px;
  }

  .marginBottomMd {
    margin-bottom: 8px;
  }

  .copyButtonContainer {
    font-size: 12px;
  }

  .userInitial {
    @extend .businessImage;

    width: 40px;
    min-width: 40px;
    height: 40px;
    background-color: $secondaryLight;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .userInitialText {
    text-transform: uppercase;
    color: $grey800;
    font-weight: 400;
    font-feature-settings: "clig" off, "liga" off;
  }
}

@media (max-width: 1200px) {
  .rightSidebarWrapper {
    font-size: 14px;
    margin: 55px 0 15px;
  }
}

@media (max-width: $breakpointMD) {
  .rightSidebarWrapper {
    margin: 0;
    padding: 0;
    overflow-y: scroll;

    .accordionDetails {
      padding: 0 16px 16px;
    }
  }
}

@media only screen and (min-width: $breakpointMD) and (max-width: $breakpointLG) {
  .rightSidebarWrapper {
    .accordionDetails {
      padding: 0 10px 10px;
    }
  }
}
