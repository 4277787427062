@import "variables.scss";
.vendorFeatures {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}

.vendorFeaturesTag {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  background-color: var(--grey-200);
  padding: 6px 12px;
  border-radius: var(--borderRadiusSmall);
}

.vendorFeaturesTag > p {
  white-space: nowrap;
}
