@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.select {
  &:global(.MuiAutocomplete-root) {
    background-color: $grey50;
    &:focus-visible {
      outline: none;
    }
  }
}
